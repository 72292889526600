export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        badge: {
          color: "primary",
          text: "HOT!!",
        },
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Anagrafica",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "Utenti",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Indirizzi email",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Social account",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Famiglie",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Ruoli operativi",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Geo",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "Timezone",
            to: "/loonity/geo/timezone",
            icon: "cil-puzzle",
          },
          {
            name: "Language",
            to: "/loonity/geo/language",
            icon: "cil-puzzle",
          },
          {
            name: "Currency",
            to: "/loonity/geo/currency",
            icon: "cil-puzzle",
          },
          {
            name: "Country",
            to: "/loonity/geo/country",
            icon: "cil-puzzle",
          },
          {
            name: "Admin1Code",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Admin2Code",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Locality",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "PostalCode",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "TaxRate",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Prodotti e produttori",
        route: "/loonity/products",
        icon: "cil-puzzle",
        items: [
          {
            name: "Produttori",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "Prodotti",
            to: "/loonity/products/products",
            icon: "cil-puzzle",
          },
          {
            name: "Lotti",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Ordini",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "correnti",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "in arrivio",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "archivio",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Contabilità",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "cassa",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "fatture da elaborare",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "archivio",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "impostazioni",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Esportazioni",
        route: "/dropdown",
        icon: "cil-puzzle",
        items: [
          {
            name: "tabella iva",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "fatture da elaborare",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "fatture",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "prodotti e lotti",
            to: "/uc",
            icon: "cil-puzzle",
          },
          {
            name: "ordini",
            to: "/uc",
            icon: "cil-puzzle",
          },
        ],
      },
    ],
  },
];
